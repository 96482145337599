<template>
  <div class="loan_item">
    <div class="inner inner1">
      <div class="content">
        <div class="left_cell">
          <h2>More than <span>6 million customers</span>  partner with</h2>
          <h2>HelaCash to achieve their financial goals</h2>
          <p class="inner1_text">4.0+ out of 5 on Google Play and over 1 million 5-star reviews globally.
          </p>
        </div>
        <div class="right_cell">
          <!-- <img src="~@/assets/images/loanImg/about_us.png"> -->
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <div class="inner5_item">
        <div class="content">
          <img src="@/assets/images/loanImg/home-inner5-1.png" alt="">
          <div class="right_item">
            <div class="item_info">
              <h2>Shila</h2>
              <h3>Google Play Review</h3>
              <p>“Excellent application for simple
                & quick loan.I will received my
                loan within 10 minutes. Giving
                more than 5  stars to HelaCash
                loan application.Thanks.”</p>
            </div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/loanImg/home-inner5-2.png" alt="">
          <div class="right_item">
            <div class="item_info">
              <h2>Mahadeva</h2>
              <h3>Google Play Review</h3>
              <p>“Very reliable and super fast
                application process. Thank you
                so much for helping me in my
                emergency case.I highly
                recommendeveryone to
                use this amazing loanapplication
                for your needs.”
              </p>
            </div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/loanImg/home-inner5-3.png" alt="">
          <div class="right_item">
            <div class="item_info">
              <h2>Swapnil</h2>
              <h3>Google Play Review</h3>
              <p>“This app is fantastic and genuine.l
                recommend everyone to use this
                appin their time of need. lt will surely
                help you.”</p>
            </div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/loanImg/home-inner5-4.png" alt="">
          <div class="right_item">
            <div class="item_info">
              <h2>Sushil</h2>
              <h3>Google Play Review</h3>
              <p>“I like [HelaCash] because it’s
                easy,and anyone can do it. It
                allows me to buy stock for our
                shop, and then make a profit. It
                helps, and we stay “rolling”.”</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="left_item">
        <h1>Read more reviews on Google Play</h1>
        <p>Apply for a Tala loan through our app and start building your financial future!</p>
        <div @click="downloadApp()">
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=fastcredit.instahela.app'
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: #333;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      .inner1_text{
        color: #FFFFFF90;
        line-height: 25px;
        margin: 40px 0;
        text-align: center;
        font-size: 14px;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #EE7E01;
      }
    }
  }
  .bottom-20{
    margin-bottom: 20px;
  }
  .inner1{
    padding: 180px 0;
    background: url("~@/assets/images/loanImg/inner1_3.png") no-repeat;
    background-size: 100% 100%;
    .content{
      display: flex;
      padding: 0px calc((100% - 1300px) / 2) 0;
      .left_cell,.right_cell{
        flex: 1;
      }
      .left_cell{
        flex: 1.3;
        padding: 90px 30px 40px 50px;
        background: linear-gradient(to bottom,#A4C519 0% ,#A4C519 5%, #ED7E00 5% ,#ED7E00 100%);
        h2{
          text-align: left;
          color: white;
          margin-bottom: 10px;
          font-size: 25px;
          span{
            font-size: 30px;
            color: #FFDE44;
          }
        }
      }
      .right_cell{
        flex: 1.5;
        img{
          margin: 0px auto 25px!important;
          width: 500px;
          height: 440px;
          display: block;
        }
      }
    }
  }
  .inner3{
    display: flex;
    height: 230px;
    padding:0 calc((100% - 1100px) / 2) 0;
    margin-bottom: 80px;
    .left_item{
      flex: 1;
      background: url("~@/assets/images/loanImg/inner2_bg.png") no-repeat;
      background-size: 100% auto;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1{
        // margin-left: 30px;
        // text-align: center;
        width: 450px;
        font-weight: bold;
        line-height: 40px;
        font-size: 25px;
        margin-bottom: 20px;
        margin-left: 140px;
      }
      p{
        font-size: 13px;
        margin-left: 160px;
      }
      div{
        position: absolute;
        right: 20px;
        top: calc(50% - 20px);
        img{
          display: block;
          width: 150px;
          margin: 0 auto;
        }
      }
    }
  }
  .inner5{
    padding: 50px calc((100% - 1100px) / 2) ;
    h2{
      line-height: 30px;
    }
    h3{
      font-size: 17px;
      color: #333333;
      font-weight: 100;
      line-height: 40px;
      border-bottom: 1px solid #ED7E00;
      margin-bottom: 30px;
    }
    .inner5_item{
      // width: 100%;
      display: flex;
      font-size: 14px;
      line-height: 16px;
      flex-wrap: wrap;
      .content{
        flex: 1;
        background: #EEEEEE;
        padding: 40px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 30px 30px 0;
        img{
          // margin-top: 50px;
          width:240px;
        }
        .right_item{
          margin-left: 20px;
          height: 100%;
          p{
            color: #666666;
            line-height: 20px;
          }
        }
      }
      & .content:nth-child(even){
        margin: 0 0px 30px 0;
      }
    }
  }
}
@media only screen and (max-width: 800px){
  .loan_item{
    margin-top: 70px;
    padding-bottom: 00px;
    // .inner{
    //   min-width: calc(100vw) !important;
    // }
    .inner1{
      padding: 20px;
      background: white;
      .content{
        display: flex;
        .left_cell{
          padding: 40px 0;
          flex: 1;
          h2{
            text-align: center;
            font-size: 14px;
            span{
              font-size: 16px;
            }
          }
          .inner1_text{
            margin: 0;
            font-size: 12px;
          }
        }
        .right_cell{
          flex: 0;
        }
      }
    }
    .inner5{
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 0 calc((100% - 1100px) / 2);
      .inner5_item{
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          margin: 20px 0px !important;
          padding: 40px;
          flex-direction:column;
          img{
            width: 100%;
            margin-bottom: 20px;
          }
          .right_item{
            margin-left: 0;
          }
        }
      }
    }
    .inner3{
      padding: 20px 20px;
      flex-direction: column;
      margin-bottom:0;
      .left_item{
        position: relative;
        h1{
          font-size: 20px;
          margin-left: 0;
          margin-top: 80px;
          text-align: center;
          margin-bottom: 0;
        }
        p{
          text-align: center;
          margin-bottom: 20px;
          margin-left: 0 !important;
        }
        div{
          position: absolute;
          top: 15px;
          img{
            width: 120px;
            height: 40px;
            margin: 0 auto 5px;
          }
        }
      }
      .right_item{
        margin-right: 0;
        width: calc(100vw - 40px);
        .item_info{
          background: #F8F9FF !important;
        }
        p{
          font-size: 18px;
        }
        // img{
        //   // width: 100%;
        //   // height: 60px;
        // }
        .item_info{
          flex-direction: column;
        }
        .info_tips{
          width: calc(100vw - 40px);
          margin-bottom: 20px;
          margin-right: 0px;
          p{
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
</style>
